<template>
    <v-container class="pt-0 pb-0">
        <v-row justify="center">

            <v-col class="flex-grow-0">
                <v-card 
                    tile
                    width="300"
                    class="pb-0"
                    flat
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0"
                        two-line
                    >
                        <v-list-item-group color="primary">
                            <v-list-item>                                
                                <v-list-item-content
                                    class="pa-0 mb-3 mt-3"
                                >
                                    <v-list-item-title>
                                        <span class="pr-1">Name:</span><span class="font-weight-bold">{{ studentName }}</span>                                   
                                    </v-list-item-title>                                    
                                </v-list-item-content>                                
                            </v-list-item>
                            <v-list-item>                                
                                <v-list-item-content
                                    class="pa-0 mb-3"
                                >
                                    <v-list-item-title>
                                        <span class="pr-1">Subject:</span><span class="font-weight-bold">{{ lessonSelected.subjectTitle }}</span>                                    
                                    </v-list-item-title>                                    
                                </v-list-item-content> 
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>                    
                    <div class="d-flex pa-1">
                        <v-text-field
                            label="Class"
                            class="mr-1 caption centered-input small-input"
                            dense
                            outlined                        
                            v-model="lessonSelected.className"
                            hide-details
                        ></v-text-field>
                        <v-text-field
                            label="Year"
                            class="mr-1 caption centered-input"
                            dense
                            outlined
                            v-model="academicYear"
                            hide-details
                        ></v-text-field>
                        <v-text-field
                                label="Term"
                                v-model="termName"
                                class="mr-1 caption centered-input small-input term-name"
                                dense
                                outlined
                                hide-details
                        ></v-text-field>
                   </div>   
                </v-card>                
            </v-col>
            
            <v-col
                class="d-flex align-end justify-center mb-2 flex-grow-0"
            >
               
                <v-btn  
                    :color="themeColor"
                    dark
                    @click="nextSubjectClass"
                >Next Subject Class</v-btn>
               
            </v-col>
            
            <v-col class="flex-grow-0">
                <v-card
                    height="116"
                    width="155"                    
                    tile 
                    class="pb-0"
                    flat 
                    outlined                                   
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0 fill-height"                                               
                    >
                        <v-list-item-group 
                            color="primary"
                            class="d-flex flex-column fill-height"
                        >
                            <v-list-item
                                v-for="(item, i) in classSummary"
                                :key="i"                            
                            >
                                <v-list-item-content>
                                    <v-list-item-title 
                                        v-text="item.text"
                                        class="caption"
                                    ></v-list-item-title>
                                </v-list-item-content>                               
                                <v-list-item-action
                                    class="ma-0"
                                >
                                    <v-chip 
                                        label
                                        outlined
                                        color="blue"
                                        small                                   
                                    >
                                    {{ item.value }}
                                    </v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>    
                    </v-list>                    
                </v-card>
            </v-col>                
            
            <v-col v-if="image" class="flex-grow-0">
                <v-card
                    width="100"                    
                    tile                     
                    class="pb-0"                                    
                >
                    <v-img                    
                        :src="imageSrc"
                        width="100"                        
                    ></v-img>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    watch: {
        studentSelected: {
            handler: function (val) {
                //console.log('marksheetheader:', val);
                this.studentName = val.studentName;
                if(val.picture === null){
                    this.imageSrc = null;
                }
                else{
                    this.imageSrc = "/img/students/" + val.picture;                    
                }
            },
            deep: true,
        },
        
        studentsRegistered: {
            handler: function (val) {
                this.classSummary[0].value = val; 
                this.classSummary[1].value = val;
            }
        },
        studentsMarksEntered: {
            handler: function (val) {                 
                this.classSummary[2].value = val;
            }
        }
    },
    computed: {
        ...mapGetters({
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            lessonSelected: 'termReports/getLessonSelected',
            academicYear: 'termReports/getAcademicYear',
            term: 'termReports/getCurrentTerm',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',
            studentName: 'termReports/getMarkSheetStudentSelected',
                      
        }),        
        themeColor(){
            return 'primary';
        },
        termName(){
            let termName = '';
            if(this.term == 1) termName =  '1';
            if(this.term == 2) termName = '2';
            if(this.term == 3) termName = '3';
            return termName
        }
    },
    data(){
        return {                     
            nameLabel: "Name",
            imageSrc: '',           
            classSummary: [
                {text: '# In Class:', value: ''},
                {text: '# Registered:', value: ''},
                {text: '# Entered:', value: ''},
            ],
            image: false,
        }
    },
    methods: {
        ...mapMutations({
            setOverlay: 'termReports/setMarkSheetOverlay',
            expand: 'termReports/setExpandLessonOptions',            
            table2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
            setOverlayLessons: 'termReports/setOverlayLessons',
        }),
        
        nextSubjectClass(){
            this.table2Records([]);
            this.setOverlayLessons(true);
            this.setOverlay(true);
            this.setStudentName('');            
            // //this.expand(true);
            
            
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input{
        text-align: center;
    }
    .small-input >>> input{
        width: 35px;;
    }
    .v-list-item{
        min-height: 0;
    } 
    .term-name >>> input{
        width: 100px !important
    }  
</style>